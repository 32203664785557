html,
body,
#root {
  height: 100%;
  margin: 0;
  background-color: #ffffff;
  font-family: Muli;
}

:root {
  --amplify-primary-color: #2743aa;
  --amplify-primary-tint: #2743aa;
  --amplify-primary-shade: #162942;
}
